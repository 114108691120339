<template>
  <base-page-layout :title="title">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-row justify="center" class="px-5">
              <v-col cols="6">
                <mf-stepper :steppers="steppers" colors-type="starlord" :step="1"></mf-stepper>
              </v-col>
            </v-row>
            <mf-card-container
              :title="title + ' do integrador'"
              description="Aqui é realizada a vinculação da conta ao machine id que foi gerado na máquina do cliente"
            >
              <v-form ref="form" class="px-10 py-16" lazy-validation style="background-color: none;">
                <v-row>
                  <v-col class="px-4" cols="6">
                    <v-row no-gutters justify="end">
                      <v-col>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <mf-text-input :value="accountId" label="Id da conta" disabled></mf-text-input>
                            </div>
                          </template>
                          <span>
                            Id da conta que a conexão vai ficar vinculada
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="px-4" cols="6">
                    <v-row no-gutters>
                      <v-col>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <mf-text-input v-model="machine_id_val" :rules="[notEmptyRule]" label="Machine id"></mf-text-input>
                            </div>
                          </template>
                          <span>
                            Este campo se refere ao id gerado na máquina do cliente
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="px-4" cols="6">
                    <v-row no-gutters justify="end">
                      <v-col>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                              <mf-text-input v-model="instance_id_val" :disabled="edit" :rules="[notEmptyRule]" label="Instance id"></mf-text-input>
                            </div>
                          </template>
                          <span>
                            Este campo é gerado aleatoriamente e pode ser editado.
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </mf-card-container>
            <v-row class="px-5">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-row justify="start">
                      <v-col class="mt-3" cols="auto">
                        <mf-button label="Cancelar" outlined color="error" @click="cancel"></mf-button>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row justify="end">
                      <v-col cols="auto">
                        <mf-action-buttons
                          :primary-button="{
                            text: 'Continuar',
                            action: goNextStep,
                            isVisible: true,
                            isDisabled: false,
                            isLoading: false
                          }"
                          :secondary-button="{
                            text: 'Salvar como rascunho',
                            action: saveDraf,
                            isVisible: false,
                            isLoading: false
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-page-layout>
</template>

<script>
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue')
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    dataCon: {
      type: Object,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      machine_id_val: '',
      instance_id_val: this.generateUnitId(),
      existing_unit_id: false,
      title: '',
      steppers: [
        {
          step: 1,
          complete: true,
          stepName: 'Cadastrar Integrador',
          pathName: ''
        },
        {
          step: 2,
          complete: false,
          stepName: 'Configurar Integrador',
          pathName: ''
        }
      ],
      notEmptyRule: v => !!v || 'Campo obrigatório'
    }
  },
  mounted() {
    this.setValues()
    this.title = this.edit ? 'Edição' : 'Cadastro'
  },
  methods: {
    setValues() {
      if (this.dataCon) {
        this.instance_id_val = this.dataCon.instance_id
        this.machine_id_val = this.dataCon.machine_id
      }
    },
    generateUnitId() {
      return (
        String(this.accountId) +
        '_' +
        Math.random()
          .toString(36)
          .slice(-6)
      )
    },
    saveDraf() {
      this.$emit('saveDraft', {
        machine_id: this.machine_id_val,
        instance_id: this.instance_id_val,
        account_id: this.accountId
      })
    },
    goNextStep() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      this.$emit('nextStep', {
        machine_id: this.machine_id_val,
        instance_id: this.instance_id_val,
        account_id: this.accountId
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style></style>
