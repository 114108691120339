<template>
  <base-page-layout :title="title">
    <v-row no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col>
            <v-row justify="center" class="px-5">
              <v-col cols="6">
                <mf-stepper :steppers="steppers" colors-type="starlord" :step="2"></mf-stepper>
              </v-col>
            </v-row>
            <mf-card-container title="Listagem das integrações" description="Aqui voce pode cadastrar as integrações que ficaram vinculadas a conexão criada.">
              <v-row justify="end">
                <v-col cols="auto">
                  <mf-button label="Adicionar mais uma integração" icon="add" @click="addNewIntegrator"></mf-button>
                </v-col>
              </v-row>
              <v-form ref="form" class="px-5" lazy-validation style="background-color: none">
                <v-expansion-panels focusable>
                  <v-expansion-panel v-for="(item, i) in integrations" :key="i">
                    <v-expansion-panel-header> Integrador - {{ item.executor }} </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="!isNewIntegration(integrations[i])" justify="end">
                        <v-col cols="auto">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs" v-on="on">
                                <v-hover v-model="hover_icon">
                                  <v-icon color="error" @click="removeIntegration(i)">{{ hover_icon ? 'mdi-delete-empty' : 'mdi-delete' }}</v-icon>
                                </v-hover>
                              </div>
                            </template>
                            <span> Remover a integração </span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <form-db-sync
                        v-if="item.executor === 'dbsync'"
                        class="mt-5"
                        :edit="isNewIntegration(integrations[i])"
                        :data-account="dataAccount"
                        :units="units"
                        :integration.sync="integrations[i]"
                      />
                      <form-cloud-sync
                        v-if="item.executor === 'cloudsync'"
                        class="mt-5"
                        :edit="isNewIntegration(integrations[i])"
                        :integration.sync="integrations[i]"
                      />
                      <form-order-sync
                        v-if="item.executor === 'ordersync'"
                        class="mt-5"
                        :units="units"
                        :edit="isNewIntegration(integrations[i])"
                        :integration.sync="integrations[i]"
                        :json-error.sync="jsonError"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-form>
            </mf-card-container>
            <v-row justify="end">
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-row justify="start">
                      <v-col class="mt-3" cols="auto">
                        <mf-button label="Voltar" outlined color="error" @click="returnStep"></mf-button>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6">
                    <v-row justify="end">
                      <v-col cols="auto">
                        <mf-action-buttons
                          :primary-button="{
                            text: 'Salvar',
                            action: save,
                            isVisible: true,
                            isDisabled: integrations.length === 0,
                            isLoading: false
                          }"
                          :secondary-button="{
                            text: 'Salvar como rascunho',
                            action: saveDraf,
                            isVisible: false,
                            isLoading: false
                          }"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <friday-integrators-dialog v-if="showFridayIntegratorsDialog" @input="closeFridayIntegratorsDialog" @select-integrator="selectIntegrator" />
  </base-page-layout>
</template>

<script>
export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    FridayIntegratorsDialog: () => import('./FridayIntegratorsDialog.vue'),
    FormDbSync: () => import('./FormDbSync.vue'),
    FormCloudSync: () => import('./FormCloudSync.vue'),
    FormOrderSync: () => import('./FormOrderSync.vue')
  },
  props: {
    accountId: {
      type: String,
      required: true
    },
    dataCon: {
      type: Array,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    dataAccount: {
      type: Object
    },
    units: {
      type: Array
    }
  },
  data() {
    return {
      options: { mode: 'code', mainMenuBar: false },
      title: '',
      showFridayIntegratorsDialog: false,
      dialog_cron: false,
      hover_icon: false,
      jsonError: {
        error: false,
        detail: ''
      },
      integrations: [
        {
          job_id: this.generateGenericToken(),
          executor: 'dbsync',
          config: {
            host: '',
            port: 0,
            user: '',
            password: '',
            database: '',
            driver: '',
            query: '',
            bucket: this.generateBucket(),
            prefix: 'ecommerce_sync/products/_/complete'
          },
          cron_schedule: '',
          type: 'recurrent'
        }
      ],
      steppers: [
        {
          step: 1,
          complete: true,
          stepName: 'Cadastrar Integrador',
          pathName: ''
        },
        {
          step: 2,
          complete: false,
          stepName: 'Configurar Integrador',
          pathName: ''
        }
      ],
      notEmptyRule: v => !!v || 'Campo obrigatório'
    }
  },
  mounted() {
    this.setValues()
    this.title = this.edit ? 'Edição' : 'Cadastro'
  },
  methods: {
    isNewIntegration(integrator) {
      return this.edit && integrator.hasOwnProperty('__typename')
    },
    generateBucket() {
      if (this.dataAccount) {
        return 'friday_' + this.dataAccount.db_name.toLowerCase()
      }
      return ''
    },
    removeIntegration(index) {
      this.integrations.splice(index, 1)
    },
    setValues() {
      if (this.dataCon) {
        this.integrations = this.dataCon
      }
    },
    generateGenericToken() {
      return Math.random()
        .toString(36)
        .slice(-6)
    },
    saveDraf() {
      this.$emit('saveDraft', this.integrations)
    },
    save() {
      if (!this.$refs.form.validate())
        return this.$alert({
          alert_message: 'Falha ao validar campos',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      if (this.jsonError.error) {
        return this.$alert({
          alert_message: this.jsonError.detail === '' ? `JSON inválido!` : this.jsonError.detail,
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.$emit('saveConnection', this.integrations)
    },
    returnStep() {
      this.$emit('returnStep')
    },
    closeFridayIntegratorsDialog() {
      this.showFridayIntegratorsDialog = false
    },
    selectIntegrator(integrator) {
      if (integrator === 'dbsync') {
        this.integrations.push({
          job_id: this.generateGenericToken(),
          executor: 'dbsync',
          config: {
            host: '',
            port: 0,
            user: '',
            password: '',
            database: '',
            driver: '',
            query: '',
            bucket: this.generateBucket(),
            prefix: 'ecommerce_sync/products/_/complete'
          },
          cron_schedule: '',
          active: true,
          type: 'recurrent'
        })
      }

      if (integrator === 'ordersync') {
        this.integrations.push({
          job_id: this.generateGenericToken(),
          executor: 'ordersync',
          config: {
            driver: '',
            erp: {},
            mercashop: {
              units: '',
              url: '',
              secret_key: ''
            }
          },
          cron_schedule: '',
          active: true,
          type: 'recurrent'
        })
      }
      if (integrator === 'cloudsync') {
        this.integrations.push({
          job_id: this.generateGenericToken(),
          executor: 'cloudsync',
          config: {
            dest_dir: '',
            provider: '',
            source: '',
            bucket: this.generateBucket(),
            prefix: 'ecommerce_sync/orders/'
          },
          cron_schedule: '',
          active: true,
          type: 'recurrent'
        })
      }
    },
    addNewIntegrator() {
      this.showFridayIntegratorsDialog = true
    }
  }
}
</script>

<style>
.ace_gutter {
  z-index: 0 !important;
}
</style>
