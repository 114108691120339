export default function filterJson(json) {
  if (json !== null && typeof json === 'object') {
    return Array.isArray(json)
      ? json.map(item => filterJson(item)).filter(item => item !== null && item !== undefined)
      : Object.fromEntries(
          Object.entries(json)
            .filter(([key, value]) => value !== null && value !== undefined && key !== '__typename')
            .map(([key, value]) => [key, filterJson(value)])
        )
  }
  return json
}
