var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":_vm.title}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"px-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('mf-stepper',{attrs:{"steppers":_vm.steppers,"colors-type":"starlord","step":1}})],1)],1),_c('mf-card-container',{attrs:{"title":_vm.title + ' do integrador',"description":"Aqui é realizada a vinculação da conta ao machine id que foi gerado na máquina do cliente"}},[_c('v-form',{ref:"form",staticClass:"px-10 py-16",staticStyle:{"background-color":"none"},attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{staticClass:"px-4",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"value":_vm.accountId,"label":"Id da conta","disabled":""}})],1)]}}])},[_c('span',[_vm._v(" Id da conta que a conexão vai ficar vinculada ")])])],1)],1)],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"rules":[_vm.notEmptyRule],"label":"Machine id"},model:{value:(_vm.machine_id_val),callback:function ($$v) {_vm.machine_id_val=$$v},expression:"machine_id_val"}})],1)]}}])},[_c('span',[_vm._v(" Este campo se refere ao id gerado na máquina do cliente ")])])],1)],1)],1),_c('v-col',{staticClass:"px-4",attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('mf-text-input',{attrs:{"disabled":_vm.edit,"rules":[_vm.notEmptyRule],"label":"Instance id"},model:{value:(_vm.instance_id_val),callback:function ($$v) {_vm.instance_id_val=$$v},expression:"instance_id_val"}})],1)]}}])},[_c('span',[_vm._v(" Este campo é gerado aleatoriamente e pode ser editado. ")])])],1)],1)],1)],1)],1)],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Cancelar","outlined":"","color":"error"},on:{"click":_vm.cancel}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-action-buttons',{attrs:{"primary-button":{
                          text: 'Continuar',
                          action: _vm.goNextStep,
                          isVisible: true,
                          isDisabled: false,
                          isLoading: false
                        },"secondary-button":{
                          text: 'Salvar como rascunho',
                          action: _vm.saveDraf,
                          isVisible: false,
                          isLoading: false
                        }}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }