var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-page-layout',{attrs:{"title":_vm.title}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"px-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('mf-stepper',{attrs:{"steppers":_vm.steppers,"colors-type":"starlord","step":2}})],1)],1),_c('mf-card-container',{attrs:{"title":"Listagem das integrações","description":"Aqui voce pode cadastrar as integrações que ficaram vinculadas a conexão criada."}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Adicionar mais uma integração","icon":"add"},on:{"click":_vm.addNewIntegrator}})],1)],1),_c('v-form',{ref:"form",staticClass:"px-5",staticStyle:{"background-color":"none"},attrs:{"lazy-validation":""}},[_c('v-expansion-panels',{attrs:{"focusable":""}},_vm._l((_vm.integrations),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(" Integrador - "+_vm._s(item.executor)+" ")]),_c('v-expansion-panel-content',[(!_vm.isNewIntegration(_vm.integrations[i]))?_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-hover',{model:{value:(_vm.hover_icon),callback:function ($$v) {_vm.hover_icon=$$v},expression:"hover_icon"}},[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeIntegration(i)}}},[_vm._v(_vm._s(_vm.hover_icon ? 'mdi-delete-empty' : 'mdi-delete'))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Remover a integração ")])])],1)],1):_vm._e(),(item.executor === 'dbsync')?_c('form-db-sync',{staticClass:"mt-5",attrs:{"edit":_vm.isNewIntegration(_vm.integrations[i]),"data-account":_vm.dataAccount,"units":_vm.units,"integration":_vm.integrations[i]},on:{"update:integration":function($event){return _vm.$set(_vm.integrations, i, $event)}}}):_vm._e(),(item.executor === 'cloudsync')?_c('form-cloud-sync',{staticClass:"mt-5",attrs:{"edit":_vm.isNewIntegration(_vm.integrations[i]),"integration":_vm.integrations[i]},on:{"update:integration":function($event){return _vm.$set(_vm.integrations, i, $event)}}}):_vm._e(),(item.executor === 'ordersync')?_c('form-order-sync',{staticClass:"mt-5",attrs:{"units":_vm.units,"edit":_vm.isNewIntegration(_vm.integrations[i]),"integration":_vm.integrations[i],"json-error":_vm.jsonError},on:{"update:integration":function($event){return _vm.$set(_vm.integrations, i, $event)},"update:jsonError":function($event){_vm.jsonError=$event},"update:json-error":function($event){_vm.jsonError=$event}}}):_vm._e()],1)],1)}),1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"auto"}},[_c('mf-button',{attrs:{"label":"Voltar","outlined":"","color":"error"},on:{"click":_vm.returnStep}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mf-action-buttons',{attrs:{"primary-button":{
                          text: 'Salvar',
                          action: _vm.save,
                          isVisible: true,
                          isDisabled: _vm.integrations.length === 0,
                          isLoading: false
                        },"secondary-button":{
                          text: 'Salvar como rascunho',
                          action: _vm.saveDraf,
                          isVisible: false,
                          isLoading: false
                        }}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1),(_vm.showFridayIntegratorsDialog)?_c('friday-integrators-dialog',{on:{"input":_vm.closeFridayIntegratorsDialog,"select-integrator":_vm.selectIntegrator}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }